<template>

  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Content')"
      icon="mdi-table-of-contents"
      width="760px"
  >
    <div class="mt-6 fill-height" style="width: 100%">

      <!-- Side Nabvigation-->
      <v-sheet v-if="!SM"
               :style="`position: fixed; left: ${$store.state.dashboard.sideMenu ? 280 : 75 }px; z-index: 999; transition : all 0.3s ease`">
        <v-hover v-slot="{hover}">
          <div>

            <v-slide-x-transition mode="out-in">

              <v-sheet key="nav_lines" v-if="!hover" color="transparent" min-height="100px">
                <v-sheet
                    color="transparent"
                    v-for="(module,i) in entity.modules"
                    :key="'nav_module' + i"
                    class="pointer">

                  <v-sheet
                      :color="intersectedItem === ('module_' + i ) ? wsATTENTION : wsBACKGROUND "
                      class="my-4"
                      height="px">
                    <hr style="opacity: 0 " width="32">
                  </v-sheet>

                  <v-sheet
                      color="transparent"
                      v-for="(topic,j) in module.topics"
                      :key="'nav_module' + i + '_' + j"
                      class="pointer">

                    <v-sheet
                        :color="intersectedItem === ('topic_' + i + '_' + j ) ? wsATTENTION : wsBACKGROUND "
                        class="my-4" width="16" height="2px">
                      <hr style="opacity: 0 " width="16">
                    </v-sheet>

                  </v-sheet>


                </v-sheet>
              </v-sheet>
              <v-sheet max-height="500" class="overflow-y-auto" width="154" key="nav_text" v-else>

                <template v-for="(module,i) in entity.modules">
                  <v-hover v-slot="{hover}" :key="'text_module_' + i">
                    <h5 @click="navigate('module_' + i , module)"
                        :style="`color : ${hover ? wsATTENTION : wsACCENT}`"
                        style="font-size: 11px; font-weight: 700"
                        class="ml-4 pointer mb-3"> {{ module.name.slice(0, 16) }}</h5>
                  </v-hover>

                  <template v-for="(topic,j) in module.topics">
                    <v-hover v-slot="{hover}" :key="'text_topic_' + i + j">
                      <h5 @click="navigate('topic_' + i + '_' + j , module , topic)"
                          :style="`color : ${hover ? wsATTENTION : wsACCENT}`"
                          style="font-size: 11px; font-weight: 500"
                          class="ml-8 mb-3 pointer "> {{ topic.name.slice(0, 16) }}</h5>
                    </v-hover>
                  </template>
                </template>

              </v-sheet>

            </v-slide-x-transition>

          </div>

        </v-hover>
      </v-sheet>
      <!-- Course Content-->
      <template v-for="(module,i) in entity.modules">

        <courseEditorModule
            v-intersect="intersector"
            v-model="module.expand"
            @update="addNewItem"
            @delete="deleteItem"
            @expand="expandModule(module)"
            @reorder="reorderModule($event , module )"
            :module="module"
            :modules-length="entity.modules.length"
            :index="i"
            :id="'module_' + i"
            :ref="'module_' + i"
            :key="module.uuid + 'module'"
            :new-course="entity.is_new"
        />

        <v-expand-transition :key="i + 'module_content'" mode="in-out">
          <div v-if="module.expand">
            <template v-for="(topic,j) in module.topics">

              <courseEditorTopic
                  v-intersect="intersector"
                  v-model="topic.expand"
                  @update="addNewItem"
                  @delete="deleteItem"
                  @expand="expandTopic(topic)"
                  @reorder="reorderTopic($event , module, topic )"
                  :topic="topic"
                  :index="j"
                  :module-index="i"
                  :topics-length="module.topics.length"
                  :id="'topic_' + i + '_' + j"
                  :ref="'topic_' + i + '_' + j"
                  :key="'topic_' + topic.uuid + module.uuid"
                  :new-course="entity.is_new"
              />
              <v-expand-transition :key="'topic_content' + topic.uuid + module.uuid" mode="out-in">
                <div v-show="topic.expand">

                  <draggable v-model="topic.components"
                             :empty-insert-threshold="30"
                             :forceFallback="true"
                             group="components"
                             handle=".component-drag"
                             tag="div"
                             v-bind="opt"
                             @start="drag = true"
                             :key="'topic_drag' + topic.uuid + module.uuid"
                             @change="reorderComponents($event,module.uuid,topic)"
                             @end=" drag = false">

                    <template v-for="(component,k) in topic.components">
                      <courseEditorComponent
                          @update="addNewItem"
                          @updateComponent="updateComponent($event, i,j,k)"
                          @delete="deleteItem"
                          :component="component"
                          :index="k"
                          :topic-index="j"
                          :module-index="i"
                          :key="'component_' + component.uuid + topic.uuid + module.uuid"
                          :new-course="entity.is_new"
                          :course-has-payment-plans="entity.has_payment_plans"
                          :payment-plans="entity.payment_plans || []"
                      />
                    </template>


                  </draggable>

                </div>
              </v-expand-transition>

            </template>
          </div>
        </v-expand-transition>

      </template>


      <div class="d-flex justify-center align-center mt-16  pb-12"
           :class="isBlankCourse ? 'mt-8' : ''"
           id="add_new_course_items_buttons"
           style="width: 100%"
      >
        <template v-if="!SM">
          <template v-for="(section,i) in newItemsSelect">
            <v-sheet
                :style="`border-color: ${wsACCENT} `"
                :key="'new_item_section_' + i"
                class="d-flex wsRoundedLight "
                :class="i === 0 ? 'mr-2' : '' "
                outlined
            >
              <template v-for="(item,j) in section">

                <v-btn :color="wsACCENT"
                       @click="addNewItemButton(item.value)"
                       style="border-radius: unset !important"
                       :key="'divider_'+i+j"
                       class="noCaps"
                       text
                >

                  <h5 style="font-size: 13px ; font-weight: 600">
                    {{ item.text }}
                  </h5>

                </v-btn>
                <v-divider
                    v-if="j < section.length -1"
                    :style="`border-color: ${wsACCENT} `"
                    :key="'divider_'+i+j"
                    vertical
                />

              </template>


            </v-sheet>
          </template>
        </template>
        <template v-else>
          <ft-select
              @input="addNewItemButton($event)"
              :items="addItemsSelect"
              :icon-color="wsATTENTION"
              :min-height="$store.state.courses.course.online ? '340' : '300'"
              colorBlack >
            <v-btn style="width: 100%" block outlined class="noCaps px-4" :color="wsACCENT">
              <v-icon>mdi-plus</v-icon>
              {{  $t('AddComponent')  }}
            </v-btn>
          </ft-select>
        </template>

      </div>

      <div v-if="isBlankCourse" >
        <div class="d-flex justify-center my-6">
          <v-icon color="grey">mdi-arrow-up</v-icon>
        </div>
        <h5 class="text-center grey--text">{{ $t('AddNewCourseItemDescription') }}</h5>
      </div>


    </div>
  </sub-page>


</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

import courseEditorModule
  from "@/components/pages/westudy/admin/course/coureseEditor/courseEditorModule";
import courseEditorTopic
  from "@/components/pages/westudy/admin/course/coureseEditor/courseEditorTopic";
import courseEditorComponent
  from "@/components/pages/westudy/admin/course/coureseEditor/courseEditorComponent";
import draggable from "vuedraggable";

export default {
  name: "courseEditor",
  props: {
    uuid : {
      type : String
    },
    publishTrigger : {
      type : Number,
    }
  },
  components: {
    courseEditorModule,
    courseEditorTopic,
    courseEditorComponent,
    draggable,
  },
  data() {
    return {

      newItemSelect: '',

      intersectedItem: 'module_0',

      dayLimitsExpand: false,
      loading: false,

      uploadLogo: false,
      logoUrl: null,

      items: [],

      opt: {
        animation: 200,
        ghostClass: "ghost"
      },
      drag: false,

      selectedTopic: null,
      selectedModule: null,

      editNameModule: false,


      newEntity: true,
      displayDialog: false,
      displayTopicDialog: false,
      displayModuleDialog: false,
      entity: {
        name: "",
        components: [],
        modules: []
      },
      entityData: {
        name: {},
      },
      entityDataDefault: {
        name: {},
        alias: ''
      },
      defaultComponent: {
        name: {},
        topic_id: null,
        entity_type: 'task_video',
      },

      fileUploaded: false,
      selectedFile: {},
      redirect: false,
    }
  },
  computed: {
    ...mapState('courses', ['modulesScrollIndex', 'modulesScroll', 'selectedLang' , 'course']),
    isBlankCourse() {
      if (this.entity.modules.length === 0) {
        return true
      }
      if (this.entity.modules.length === 1) {
        if (this.entity.modules[0].topics.length === 0) {
          return true
        }
        if (this.entity.modules[0].topics.length === 1) {
          if (this.entity.modules[0].topics[0].components.length === 0) {
            return true
          }
        }
      }

      return false
    },
    newItemsSelect() {

      let components = [
        {text: this.$t('Lecture'), value: 'lecture'},
        {text: this.$t('Task'), value: 'task'},
        {text: this.$t('Test'), value: 'test'},
        {text: this.$t('VideoMeeting'), value: 'task_video'},
        {text: this.$t('Video'), value: 'video'},
        {text: this.$t('File'), value: 'file'},
      ]
      if ( !this.$store.state.courses.course.online ) {
        components.splice(3,1)
      }

      return [
        [
          {text: this.$t('Module'), value: 'module'},
          {text: this.$t('Topic'), value: 'topic'},
        ],
        components

      ]
    },

    addItemsSelect() {
      let items = [
        {text: this.$t('Module'), value: 'module', icon: 'mdi-book-variant'},
        {text: this.$t('Topic'), value: 'topic', icon: 'mdi-book-variant'},
        {text: this.$t('Lecture'), value: 'lecture', icon: 'mdi-book-open'},
        {text: this.$t('Assignment'), value: 'task', icon: 'mdi-content-paste'},
        {text: this.$t('Test'), value: 'test', icon: 'mdi-text-box-check-outline'},
        {text: this.$t('VideoMeeting'), value: 'task_video', icon: 'mdi-cast-education'},
        {text: this.$t('Video'), value: 'video', icon: 'mdi-video'},
        {text: this.$t('File'), value: 'file', icon: 'mdi-file'},
      ];
      if (!this.$store.state.courses.course.online) {
        items.splice(5, 1)
      }
      return items
    },

    showEntityTypesSelectCondition() {
      return !(this.entityData.file || this.entityData.lecture || this.entityData.task)
    },

    entityTypes() {
      return [
        //    { text : this.$t('None'),         value: null },
        {text: this.$t('VideoMeeting'), value: 'task_video'},
        {text: this.$t('Video'), value: 'video'},
        {text: this.$t('Lecture'), value: 'lecture'},
        {text: this.$t('Task'), value: 'task'},
        {text: this.$t('File'), value: 'file'},
      ]
    },
    actionTypes() {
      return [
        {text: this.$t('CreateNew'), value: 'new'}
      ]
    },

    saveText() {
      return this.newEntity ? this.$t('Add') : this.$t('Save')
    }


  },
  watch: {
    publishTrigger() {
      this.initPage()
    },
    modulesScroll() {
      this.scrollToModule(this.modulesScrollIndex)
    },
    selectedLang() {
      this.initPage()
    }
  },
  methods: {
    ...mapActions('courses', [
      'ADD_MODULE',
      'ADD_TOPIC',
      'ADD_COMPONENT',

      'PUBLISH_COURSE',
      'GET_COURSES',
      'GET_COURSE_EDIT',
      'DELETE_COURSE',
      'DELETE_MODULE',

      'GET_COMPONENT',
      'EDIT_COMPONENT',
      'SET_COMPONENT_FILE',
      'DELETE_COMPONENT',
      'REORDER_MODULES',
      'REORDER_TOPICS',
      'REORDER_COMPONENTS'
    ]),
    ...mapMutations('courses', ['INIT_PAGE']),

    updateComponent(component, moduleIndex, topicIndex , index) {
      this.entity.modules[moduleIndex].topics[topicIndex].components[index] = JSON.parse(JSON.stringify(component))
      this.refreshEntity()
    },

    navigate(id, module, topic) {

      if (!this.$refs[id]) {
        return
      }

      if (id.includes('module') && !module.expand) {
        this.expandModule(module)
      }
      if (id.includes('topic')) {
        if (!module.expand) {
          this.expandModule(module)
          setTimeout(() => {
            this.navigate(id, module, topic)
          }, 500)
          if (!topic.expand) {
            this.expandTopic(topic)
          }
        }
        if (!topic.expand) {
          this.expandTopic(topic)
        }
      }


      let container = document.getElementById('dashPage')
      if (!container) {
        return
      }

      this.$vuetify.goTo(this.$refs[id][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
            container: container
          })

    },

    intersector(entries) {
      if (entries[0]) {
        if (!entries[0].isIntersecting && entries[0].boundingClientRect.y < 0) {
          console.log(entries[0].boundingClientRect.y)
          this.intersectedItem = entries[0].target.id
        }
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y < 0) {
          console.log(entries[0].boundingClientRect.y)
          this.intersectedItem = entries[0].target.id
        }
      }
    },

    async publishCourse() {

      let result = await this.PUBLISH_COURSE(this.uuid)
      if (!result) {
        return this.notify(this.$t('NetworkError'))
      }
      this.$store.state.courses.course.active = true;
      this.entity.active = true
      this.notify(this.$t('CoursePublished'))
      await this.initPage()

    },

    deleteItem($event) {

      let result = {
        module: () => {
          this.deleteModule($event.moduleIndex)
        },
        topic: () => {
          this.deleteTopic($event.topicIndex, $event.moduleIndex)
        },
        component: () => {
          this.deleteComponent($event.componentIndex, $event.topicIndex, $event.moduleIndex)
        }
      }
      result[$event.entity]()

    },
    async addNewItem($event , scroll = false) {
      let result = {
        module: () => {
          this.addNewModule($event.data)
        },
        topic: () => {
          this.addNewTopic($event.data)
        },
        component: () => {
          this.addNewComponent($event.data)
        }
      }
      await result[$event.entity]()

      if ( scroll ) {
        this.scrollNewItem('add_new_course_items_buttons')
      }


    },

    async addModuleEntity() {

      let data = {
        course_id : this.uuid,
        lang : this.selectedLang,
        block : this.entity.modules.length + 1
      }
      let result = await this.ADD_MODULE( data )
      if ( !result ) {
        return
      }
      this.addNewItem({ entity : 'module' , data : result } , true )
    },
    async addTopicEntity() {


      let lastModule = this.entity.modules[this.entity.modules.length - 1];
      this.entity.modules[this.entity.modules.length - 1].expand = true;

      let data = {
        module_id : lastModule.uuid,
        lang : this.selectedLang,
        order_number : lastModule.topics.length + 1
      }
      let result = await this.ADD_TOPIC(data)
      if ( !result ) {
        return
      }

      this.addNewItem( { entity : 'topic' , data : result } , true )
    },
    async addComponentEntity(type) {

      let lastModule = this.entity.modules[this.entity.modules.length - 1];
      this.entity.modules[this.entity.modules.length - 1].expand = true
      if ( lastModule.topics.length === 0  ) {
        await this.addTopicEntity()
      }

      let lastTopic = lastModule.topics[lastModule.topics.length - 1];
      this.entity.modules[this.entity.modules.length - 1].topics[lastModule.topics.length - 1].expand = true

      let data = {
        entity_type : type,
        lang : this.selectedLang,
        topic_id : lastTopic.uuid,
        order_number : lastTopic.components.length + 1
      }

      let result = await this.ADD_COMPONENT(data)

      this.addNewItem({ entity : 'component' , data : result } )

    },

    addNewItemButton(entity) {

      let result = {
        module : () => {
          this.addModuleEntity(entity)
        },
        topic : () => {
          this.addTopicEntity()
        }
      }
      if ( result[entity] ) {
        return result[entity]()
      }
      this.addComponentEntity(entity)

    },

    addNewModule(module) {
      module.expand = true
      module.topics[0].expand = true

      this.entity.modules.forEach(item => {
        if (item.block >= module.block) {
          item.block++
        }
      })

      this.entity.modules.splice(module.block - 1, 0, module)
      this.entity = Object.assign({}, this.entity)
    },
    addNewTopic(topic) {
      topic.expand = true
      let index = this.entity.modules.findIndex(el => el.uuid === topic.module_id)
      if (index === -1) {
        return
      }

      this.entity.modules[index].topics.forEach(item => {
        if (item.order_number >= topic.order_number) {
          item.order_number++
        }
      })

      this.entity.modules[index].topics.splice(topic.order_number - 1, 0, topic)
      this.entity = Object.assign({}, this.entity)
    },
    addNewComponent(component) {

      component.expand = true

      let moduleIndex = this.entity.modules.findIndex(el => el.uuid === component.module_id)
      if (moduleIndex === -1) {
        return
      }

      let topicIndex = this.entity.modules[moduleIndex].topics.findIndex(el => el.uuid === component.topic_id)
      if (topicIndex === -1) {
        return
      }

      this.entity.modules[moduleIndex].topics[topicIndex].components.forEach(item => {
        if (item.order_number >= component.order_number) {
          item.order_number++
        }
      })

      this.entity.modules[moduleIndex]
          .topics[topicIndex]
          .components.splice(component.order_number - 1, 0, component)
      this.refreshEntity()
      this.scrollNewItem(component.uuid)

    },

    deleteModule(index) {
      this.entity.modules.splice(index, 1)
      this.entity.modules.forEach(item => {
        if (item.block >= index + 1) {
          item.block--
        }
      })
    },
    deleteTopic(index, moduleIndex) {
      this.entity.modules[moduleIndex].topics.splice(index, 1)

      this.entity.modules[moduleIndex].topics.forEach(item => {
        if (item.order_number >= index + 1) {
          item.order_number--
        }
      })

    },
    deleteComponent(index, topicIndex, moduleIndex) {

      this.entity.modules[moduleIndex].topics[topicIndex].components.splice(index, 1)

      this.entity.modules[moduleIndex].topics[topicIndex].components.forEach(item => {
        if (item.order_number >= index + 1) {
          item.order_number--
        }
      })
      this.refreshEntity()

    },

    expandModule(module) {
      module.expand = !module.expand
      this.entity = Object.assign({}, this.entity)
    },
    expandTopic(topic) {
      topic.expand = !topic.expand
      this.entity = Object.assign({}, this.entity)
    },


    refreshEntity() {
      this.entity = Object.assign({}, this.entity)
    },

    addComponentAction(type, module, topic) {
      this.displayNewComponent(module, topic, type)
    },

    async reorderModule($event,module) {
      this.notify('reorder modules')
      let newOrder = module.block + $event
      let data = {
        uuid : module.uuid,
        block :  newOrder,
      }
      let result = await this.REORDER_MODULES(data)
      if ( !result ) {
        return
      }
      let targetIndex = this.entity.modules.findIndex(el => el.block === newOrder )
      let currentIndex = this.entity.modules.findIndex(el => el.uuid === module.uuid)
      if ( targetIndex === -1 || currentIndex === -1 ) {
        return
      }
      this.entity.modules[targetIndex].block = module.block
      this.entity.modules[currentIndex].block = newOrder

      this.entity.modules.splice(currentIndex,1)
      this.entity.modules.splice(targetIndex,0, module)

      this.refreshEntity()

    },
    async reorderTopic($event,module,topic) {
      let newOrder = topic.order_number + $event

      let data = {
        uuid : topic.uuid,
        order_number :  newOrder,
      }

      let result = await this.REORDER_TOPICS(data)
      if ( !result ) {
        return
      }

      let moduleIndex = this.entity.modules.findIndex(el => el.uuid === module.uuid)
      if ( moduleIndex === -1 ) {
        return
      }
      let targetIndex  = this.entity.modules[moduleIndex].topics.findIndex(el => el.order_number === newOrder )
      let currentIndex = this.entity.modules[moduleIndex].topics.findIndex(el => el.uuid === topic.uuid)
      if ( targetIndex === -1 || currentIndex === -1 ) {
        return
      }
      this.entity.modules[moduleIndex].topics[targetIndex].order_number = topic.order_number
      this.entity.modules[moduleIndex].topics[currentIndex].order_number = newOrder

      this.entity.modules[moduleIndex].topics.splice(currentIndex,1)
      this.entity.modules[moduleIndex].topics.splice(targetIndex,0, topic)

      this.refreshEntity()

    },
    async reorderComponents($event, module, topic) {

      let data = {
        module_id: module,
        topic_id: topic.uuid,
        order: [],
        action: Object.keys($event)[0]
      };
      if (topic.components.length > 0) {
        data.order = topic.components.map(el => el.uuid)
      }
      if (await this.REORDER_COMPONENTS(data)) {

        let moduleIndex = this.entity.modules.findIndex(el => el.uuid === module)
        if (moduleIndex === -1) {
          return
        }
        let topicIndex = this.entity.modules[moduleIndex].topics.findIndex(el => el.uuid === topic.uuid)
        if (topicIndex === -1) {
          return
        }
        this.entity.modules[moduleIndex].topics[topicIndex].components.forEach((item, i) => {
          item.order_number = i + 1
        })
        this.refreshEntity()
      }

    },

    async updateLogo(file) {


      let data = {
        file: file,
        data: {
          course: this.uuid
        }
      }
      let result = await this.UPLOAD_COURSE_IMAGE(data)
      if (!result) {
        return this.notify('ConnectionError')
      }


      this.$store.state.courses.course.img = result.url
      this.uploadLogo = false


    },

    // Course Editor Funcitons

    async editComponent() {
      let data = await this.EDIT_COMPONENT(this.entityData)

      if (!data) {
        return this.notify('Error')
      }

      let moduleIndex = this.entity.modules.findIndex(el => el.uuid === data.module_id)
      if (moduleIndex === -1) {
        return
      }

      let topicIndex = this.entity.modules[moduleIndex].topics.findIndex(el => el.uuid === data.topic_id)
      if (topicIndex === -1) {
        return
      }

      let componentIndex = this.entity.modules[moduleIndex].topics[topicIndex].components.findIndex(el => el.uuid === data.uuid)
      if (componentIndex === -1) {
        return
      }

      this.entity.modules[moduleIndex].topics[topicIndex].components[componentIndex] = data
      this.notify(this.$t("ChangesSaved"))

      this.displayDialog = false

      this.fileUploaded = false
      this.selectedFile = {}

    },

    //file
    async bigFileUploadSuccess(data) {
      let result = await this.SET_COMPONENT_FILE(data)
      this.entityData.file_id = result.uuid
      this.selectedFile = result
      this.fileUploaded = true

    },
    async uploadBigFile() {
      if (!this.entityData.uuid) {
        await this.addComponent(true)
      }
    },
    async uploadFile(file) {
      if (!this.entityData.uuid) {
        await this.addComponent(true)
      }

      let data = {
        file: file,
        params: {
          create_entity: true,
          entity: 'course_component',
          entity_id: this.entityData.uuid
        }

      }
      this.UPLOAD_PRIVATE_FILE(data).then((out) => {
        if (out.result) {
          this.notify(this.$t('FileUploaded'))
          this.entityData.file_id = out.data.url.uuid
          this.selectedFile = out.data.url
          this.fileUploaded = true
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })

    },
    deleteFile() {

      this.loading = true

      if (this.newEntity) {
        this.DELETE_PRIVATE_FILE(this.selectedFile.uuid).then((out) => {
          if (out.result) {
            this.notify(this.$t('FileDeleted'))
            delete (this.entityData.file_id)
            this.selectedFile = {}
            this.fileUploaded = false
          }
          this.loading = false
        })
            .catch(() => {
              this.notify(this.$t('NetworkError'))
              this.loading = false
            })
      } else {

        this.entityData.removeFile = true
        if (this.entityData.file) {
          this.entityData.file_id = this.entityData.file.uuid
        }
        this.EDIT_COMPONENT(this.entityData).then((out) => {
          if (out) {

            this.notify(this.$t('FileDeleted'))
            delete (this.entityData.file_id)
            this.selectedFile = {}
            this.fileUploaded = false
            delete (this.entityData.removeFile);
            this.entityData.file = null
            this.editComponent(out)


          } else {
            this.notify(this.$t('NetworkError'))
          }
          this.loading = false
        }).catch(() => {
          this.notify(this.$t('NetworkError'))
          this.loading = false
        })
      }

    },

    // Technical
    scrollToModule(index) {
      this.$vuetify.goTo(this.$refs['module_' + index][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
          })
    },


    // entities
    getEntityLink(component) {
      switch (component.entity_type) {
        case 'lecture'    :
          return component.lecture ? 'lectures/edit/' + component.lecture.value : null
        case 'task'       :
          return component.task ? 'tasks/edit/' + component.task.value : null
        case 'task_video' :
          return component.task ? 'tasks/edit/' + component.task.value : null
        default :
          return false
      }
    },
    getEntityDisplayCondition(component) {
      switch (component.entity_type) {
        case 'lecture'    :
          return !!component.lecture
        case 'task'       :
          return !!component.task
        case 'task_video' :
          return !!component.task
        default :
          return false
      }
    },
    getEntityTypeTitle(component) {
      switch (component.entity_type) {
        case 'lecture'    :
          return this.$t('Lecture')
        case 'task'       :
          return this.$t('Task')
        case 'task_video' :
          return this.$t('VideoMeeting')
        default :
          return "none"
      }
    },
    removeEntityAction(component) {
      this.redirect = true;
      switch (component.entity_type) {
        case 'lecture' :
          return this.entityData.lecture = null
        case 'task'    :
          return this.entityData.task = null
        default :
          return
      }
    },

    scrollNewItem(id) {

      setTimeout(() => {
        let container = document.getElementById('dashPage')
        let item = document.getElementById(id)

        if (!container) {
          return
        }
        this.$vuetify.goTo(item,
            {
              duration: 300,
              offset: 70,
              easing: 'easeInOutQuad',
              container: container
            })
      }, 500)

    },

    flushEntity() {
      this.entityData = JSON.parse(JSON.stringify(this.entityDataDefault))
    },

    // Page init
    async initPage() {
      this.flushEntity()

      let data = {
        uuid: this.uuid,
        lang: this.$store.state.courses.selectedLang
      }
      this.entity = await this.GET_COURSE_EDIT(data) || [];

      this.entity.modules.forEach(module => {
        module.expand = true
        module.topics.forEach(topic => {
          topic.expand = true
          topic.components.forEach(component => {
            component.expand = false
          })
        })
      })
      this.entity = Object.assign({}, this.entity)
      this.$store.state.courses.modules = this.entity.modules
    }
  },

  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>