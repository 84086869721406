<template>
  <div>

    <div v-if="!loading">
      <div v-if="test.question_sets.length > 0" class=" mt-6 pb-6">

        <ws-accordion class="mx-6" :items="testNavigationItems">

          <template #action.questions>
            <ws-button @click="displayPreview = true" label="Preview" class="mr-6" />
          </template>

          <template #item.questions>
            <v-simple-table style="border-radius : 0 !important" class="wsHoverLight" >

              <tbody style="border-radius : 0 !important">
              <template v-for="(item,i) in test.question_sets">
                <tr  :key="'question_bank' + i + component.uuid">
                  <td :style="`border-color : ${wsBACKGROUND}`">
                    <h5> {{  item.question_bank_name  }}</h5>
                  </td>
                  <td :style="`border-color : ${wsBACKGROUND}`">
                    <h5 v-if="Object.keys(item.question_set_groups).length === 1">{{ $t('Questions')  }}:
                      {{ item.question_set_groups[Object.keys(item.question_set_groups)[0]] }}
                    </h5>
                    <h5 v-if="Object.keys(item.question_set_groups).length > 1">{{ $t('TotalQuestions')  }}:
                      {{ getBankTotalQuestions(item.question_set_groups) }}
                    </h5>
                  </td>

                </tr>
                <template  v-for="(value,key) in item.question_set_groups" >
                  <tr v-if="Object.keys(item.question_set_groups).length > 1"
                      :key="key + item.uuid + component.uuid " >

                    <td :style="`border-color : ${wsBACKGROUND}`">
                      <h5 class="pl-6 font-weight-medium" >{{ $t('QuestionsFor')}} {{ $tc('forPoints',key,{ points : key}) }}</h5>
                    </td>
                    <td :style="`border-color : ${wsBACKGROUND}`">
                      <h5>{{ $t('Questions')  }}: {{ value }} </h5>
                    </td>

                  </tr>
                </template>

              </template>

              </tbody>

            </v-simple-table>
          </template>
          <template #item.settings >
            <div class="px-6 pt-2 pb-6" >
              <v-switch class="mt-8" v-model="test.unlimited"
                        @change="editTest($event,'unlimited'); "  inset background-color="white" :color="wsACCENT" >
                <template #label>
                  <h5>{{ $t('TestUnlimitedText') }} </h5>
                  <wsTooltip :text="$t('TestUnlimitedTooltip')">
                    <v-icon class="ml-2" :color="wsACCENT">mdi-information-outline</v-icon>
                  </wsTooltip>
                </template>
              </v-switch>

              <v-expand-transition>
                <v-row v-if="test.unlimited">
                  <v-col>
                    <ws-text-field
                        v-model="test.tries"
                        @change="editTest($event,'tries')"
                        :label="$t('Tries')"
                        number number-length="4"
                    />
                  </v-col>
                  <v-col>
                    <ws-text-field
                        v-model="test.duration"
                        @change="editTest($event,'duration')"
                        number number-length="4"
                        clearable
                        :label="$t('Duration')"
                    />
                  </v-col>
                </v-row>
              </v-expand-transition>

              <v-row class="" >
                <v-col cols="6">
                  <ws-text-field
                      v-model="test.passing_score"
                      @change="editTest($event,'passing_score')"
                      placeholder="50"
                      :label="$t('PassingScore')"
                      number number-length="3"
                      hide-details
                      style="margin-top: 19px"
                  />
                </v-col>
                <v-col cols="6">
                  <ws-select
                      v-model="test.display_result"
                      @change="editTest($event,'display_result')"
                      :items="displayTestsSelect"
                      :label="$t('TestDisplayResult')"
                      :tooltip="$t('TestDisplayResultTooltip')"
                      number number-length="3"
                      hide-details
                  />
                </v-col>
              </v-row>



            </div>
          </template>

        </ws-accordion>

        <wsTextEditor
            v-model="entity.description"
            :html="entity.text_description"
            @input="editTaskContent"
            :key="component.uuid + 'after_questions'"
            :component-id="component.uuid"
        />

      </div>

      <div v-if="test.question_sets.length === 0" class="pb-6">


        <div class="d-flex justify-space-between align-center mt-6 mb-4 px-7">
          <h4 class=" " style="font-weight: 600; font-size: 20px">{{ $t('CreateTest') }}</h4>
          <v-btn @click="expandHelp=true" :color="wsACCENT" class="noCaps" text>
            <v-icon class="mr-1">mdi-help-circle-outline</v-icon>
            {{ $t('Help') }}
          </v-btn>
        </div>

        <wsNavigationHeader
            locked
            v-model="navigation"
            :items="navigationItems"
            class="mb-4 px-7 "
        />

        <div v-if="navigation === 'description'">
          <v-sheet class="pb-6" >
            <h5 class="ml-7">{{ $t('TestDescriptionText') }}: </h5>
            <wsTextEditor
                v-model="entity.description"
                :html="entity.text_description"
                @input="editTaskContent"
                :key="component.uuid + 'before_questions'"
                :component-id="component.uuid"
            />
          </v-sheet>
        </div>

        <v-sheet  v-if="navigation === 'questions'">

          <v-sheet v-if="questionBanks.length === 0"  style="height: 100% !important" class="px-7 mb-6  d-flex justify-center align-center">

            <v-sheet  class="wsRoundedLight pt-8 py-6">

              <div class="d-flex justify-center">
                <v-icon size="80" :color="wsACCENT" >mdi-forum</v-icon>
              </div>
              <h3 class="text-center px-6 my-6">{{ $t('TestNoQuestionBanksMessage') }}</h3>
              <div class="d-flex justify-center">
                <v-btn height="40" @click="newQuestionBank" dark :color="wsATTENTION" class="noCaps"> {{ $t('NewQuestionBank') }}</v-btn>
              </div>

            </v-sheet >

          </v-sheet >
          <v-sheet class="px-4" v-else >

            <h5 class="ml-3">{{ $t('ChooseQuestionsMessage') }}: </h5>
            <v-simple-table
                class="mt-4 mx-2 mb-6 wsHoverLight"
                :style="`border: 1px solid  ${wsBACKGROUND} `">

              <tbody>
              <template v-for="(item,i) in questionBanks">
                <tr  :key="'question_bank' + i + component.uuid">
                  <td :style="`border-color : ${wsBACKGROUND}`">
                    <h5> {{  item.name  }}</h5>
                  </td>
                  <td :style="`border-color : ${wsBACKGROUND}`">
                    <h5 v-if="item.questions_stats.length === 1">Доступно: {{ item.questions_count }} </h5>
                  </td>
                  <td :style="`border-color : ${wsBACKGROUND}`" width="100px">
                    <ws-tooltip :text="$t('ChooseQuestionsAmount')">
                      <ws-text-field
                          v-if="item.questions_stats.length === 1"
                          v-model="entityData[item.uuid + '_' + item.questions_stats[0].points ]"
                          :error="questionsError"
                          @input="questionsError = false"
                          @change="validateQuestionAmount($event, (item.uuid + '_' + item.questions_stats[0].points) , item.questions_stats[0] )"
                          number
                          number-length="4"
                      />
                    </ws-tooltip>
                    <h5 v-if="item.questions_count === 0" class="text-center"> {{ $t('NoQuestions') }}</h5>
                  </td>
                </tr>
                <template  v-for="(questionSet,j) in item.questions_stats" >
                  <tr v-if="item.questions_stats.length > 1"
                      :key="j + item.uuid + component.uuid " >
                    <td :style="`border-color : ${wsBACKGROUND}`">
                      <h5 class="pl-6 font-weight-medium" >{{ $t('QuestionsFor')}} {{ $tc('forPoints',questionSet.points,{ points : questionSet.points}) }}</h5>
                    </td>
                    <td :style="`border-color : ${wsBACKGROUND}`">
                      <h5>{{ $t('Available')  }}: {{ questionSet.count }} </h5>
                    </td>
                    <td :style="`border-color : ${wsBACKGROUND}`" width="100px">
                      <ws-text-field
                          v-model="entityData[item.uuid + '_' + questionSet.points]"
                          :error="questionsError"
                          @input="questionsError = false"
                          @change="validateQuestionAmount($event, item.uuid + '_' + questionSet.points , questionSet )"
                          number
                          number-length="4"
                      />
                    </td>
                  </tr>
                </template>

              </template>

              </tbody>

            </v-simple-table>
            <div class="px-2 pb-6">
              <v-btn height="40" block @click="newQuestionBank" outlined :color="wsACCENT" class="noCaps">
                <v-icon>mdi-plus</v-icon>
                {{ $t('NewQuestionBank') }}
              </v-btn>

            </div>

          </v-sheet>

        </v-sheet>

        <div v-if="navigation === 'settings'" class="px-6 pt-2 pb-6" >
          <v-switch class="mt-8" v-model="test.unlimited"
                    @change="editTest($event,'unlimited'); "  inset background-color="white" :color="wsACCENT" >
            <template #label>
              <h5>{{ $t('TestUnlimitedText') }} </h5>
              <wsTooltip :text="$t('TestUnlimitedTooltip')">
                <v-icon class="ml-2" :color="wsACCENT">mdi-information-outline</v-icon>
              </wsTooltip>
            </template>
          </v-switch>
          <v-expand-transition>
            <v-row v-if="test.unlimited">
              <v-col>
                <ws-text-field
                    v-model="test.tries"
                    @change="editTest($event,'tries')"
                    :label="$t('Tries')"
                    number number-length="4"
                />
              </v-col>
              <v-col>
                <ws-text-field
                    v-model="test.duration"
                    @change="editTest($event,'duration')"
                    number number-length="4"
                    clearable
                    :label="$t('Duration')"
                />
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-row class="" >
            <v-col cols="6">
              <ws-text-field
                  v-model="test.passing_score"
                  @change="editTest($event,'passing_score')"
                  placeholder="50"
                  :label="$t('PassingScore')"
                  number number-length="3"
                  hide-details
                  style="margin-top: 19px"
              />
            </v-col>
            <v-col cols="6">
              <ws-select
                  v-model="test.display_result"
                  @change="editTest($event,'display_result')"
                  :items="displayTestsSelect"
                  :label="$t('TestDisplayResult')"
                  :tooltip="$t('TestDisplayResultTooltip')"
                  number number-length="3"
                  hide-details
              />
            </v-col>
          </v-row>
        </div>


        <div v-if=" step !== 1 ||  questionBanks.length > 0 "
            class="d-flex justify-end px-7 ">
          <v-btn height="40" @click="nextStep" dark :color="wsATTENTION" class="noCaps"> {{ step === 2 ? $t('Save') : $t('Next') }}</v-btn>
        </div>


      </div>
      <ws-dialog width="800" title="Як працюють тести:" no-navigation v-model="expandHelp" >

        <h5 class="mt-4 font-weight-medium">Для складання тесту викристовуються Банки запитань: це каталог заздалегіть підготовлених запитань, які можливо повторно використовувати в різних тестах</h5>
        <h5 class="mt-4 font-weight-medium">Для створення тесту, Ви обираєте потрібні банки запитань, та вказуєте кількість питань, яку потрібно використати із кожного банку запитань</h5>
        <h5 class="mt-4 font-weight-medium">Система атоматично обирає в довільному порядку потрібну вказану кількість запитань для тесту, таким чином кожен стуеднт завжди має разні варіанти тесту </h5>
        <h5 class="mt-4 font-weight-medium">Система підтримує запитання різних типів складності, для чого запитання можна позначити балами, в такому разі Ви вказуєте скільки запитань певного рівня складності потрібно вибрати  </h5>

        <h5 class="mt-4 font-weight-medium"><strong>Приклад 1:</strong> Маємо 1 банк запитань на 100 запитань, та обираємо кількість питань для тесту 10 - в такому випадку студент повинен відповісти на 10 запитань, які будуть довільно обрані із всіх 100 запитань вказаного банку запитань  </h5>
        <h5 class="mt-4 font-weight-medium"><strong>Приклад 2:</strong> Маємо 1 банк запитань на 100 запитань по 1 балу , та 50 запитань по 2 бали, та обираємо кількість питань по 1 балу - 10, та кількість питань по 2 бали - 5: в такому випадку студент повинен відповісти на 15 запитань, які будуть довільно обрані, 10 запитань по 1 балу із 100 можливих, та 5 запитань по 2 бали із 50 можливих </h5>

        <h5 class="mt-4 font-weight-medium">Система автоматично рахує відповіді студента, враховуючи рівні складності запитань, та видає оцінку від 0 до 100. Тест вважається пройденим якщо студент набрав більше 50 балів  </h5>

      </ws-dialog>
    </div>

    <v-sheet v-else height="90" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate :color="wsACCENT" />
    </v-sheet>

    <test-preview-dialog
        v-if="displayPreview"
        v-model="displayPreview"
        :test-id="test.uuid"
    />


  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import testPreviewDialog
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/testPreviewDialog";

export default {
  name: "courseEditorTest",
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },
  components : {
    testPreviewDialog
  },
  data() {
    return {
      displayPreview : false,
      loading : true,
      expandHelp : false,
      entity : {},
      test : {
        question_sets : []
      },
      questionBanks : [],
      navigation : 'description',
      step : 0,
      entityData : {},
      questionsError : false,
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    testNavigationItems() {
      return [
        {name : (this.$t('IsChosen') + ' ' + this.$tc('questions',this.totalTestQuestions, {questions : this.totalTestQuestions})) , value : 'questions' },
        {name : this.$t('Settings') , value : 'settings'  },
      ]
    },
    displayTestsSelect() {
      return [
        { text : this.$t('Never'), value : null },
        { text : this.$t('DisplayTestResultOnSuccess'), value : 'on_success' },
        { text : this.$t('DisplayTestResultAlways'), value : 'always' }
      ]
    },
    totalTestQuestions() {
      let result = 0
      if (this.test.question_sets.length > 0 ) {
        this.test.question_sets.forEach( set => {
          Object.keys(set.question_set_groups).forEach(group => {
            result += set.question_set_groups[group]
          })
        })
      }
      return result
    },

    navigationItems() {
      return [
        { text : this.$t('Description') , value : 'description' },
        { text : this.$t('Questions') , value : 'questions' },
        { text : this.$t('Settings') , value : 'settings' }
      ]
    }

  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_TASK_EDIT',
      'SAVE_TASK',
    ]),
    ...mapActions('tests', [
      'ADD_QUESTION_BANK',
      'CREATE_TEST_QUESTION_SETS',
      'SAVE_TEST',
    ]),

    validateQuestionAmount($event , key, set) {
      if ( $event > set.count ) {
        this.entityData[key] = set.count.toString()
        this.notify(this.$t('BankMaximumQuestionsCountIs') + ': ' + set.count)
      }
      if ( $event < 1 ) {
        this.entityData[key] = 0
      }

    },

    async editTest(value,field) {
      if ( field === 'passing_score' && value > 100 ) {
        value = 100
        this.test.passing_score = 100
      }
      if ( field === 'passing_score' && value === ''  ) {
        this.notify('value')
        value = 50
        this.test.passing_score = 50
      }
      if ( field === 'passing_score' && value < 1 ) {
        value = 1
        this.test.passing_score = 1
      }

      let data = {
        [field] : value,
        uuid : this.test.uuid
      }
      let result = await this.SAVE_TEST(data)
      if ( !result ) {
        return this.notify('Error')
      }

    },

    async editTaskContent(content) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        description : content
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },
    async newQuestionBank() {

      let data = {
        uuid : this.component.course_id,
        lang : this.selectedLang,
      }

      let result = await this.ADD_QUESTION_BANK(data)
      if ( !result ) {
        return
      }
      this.$store.state.dashboard.questionBankReturnToCourse = true
      this.$router.push(this.businessDashLink('courses/' + this.component.course_id +  '/question_banks/edit/' + result.uuid))
    },

    //technical
    async nextStep() {

      if ( this.step === 0 ) {
        this.step++;
        this.navigation = this.navigationItems.map(el=> el.value)[this.step];
        return
      }
      if ( this.step === 1 ) {

        if ( !await this.createQuestionSets() ) {
          return
        } else {
          this.step++
          this.navigation = this.navigationItems.map(el=> el.value)[this.step];
          return
        }
      }
      if ( this.step === 2 ) {
        this.initPage()
      }

    },

    async createQuestionSets() {

      let data = {
        test_id : this.test.uuid,
        question_sets : []
      }

      Object.keys(this.entityData).forEach(bank=>{

        if ( this.entityData[bank]   ) {
          let bankId = bank.split('_')[0]
          let points = bank.split('_')[1]
          data.question_sets.push( { bank_id : bankId, amount : this.entityData[bank] , points : points  })
        }
      })

      if ( data.question_sets.length === 0 ) {
        this.questionsError = true
        this.notify(this.$t('PleaseChooseAmountOfQuestionsError'))
        return false
      }
      let result = await this.CREATE_TEST_QUESTION_SETS(data)
      if ( !result ) {
        this.notify(this.$t('Error'))
        return false
      }
      return true
    },

    getBankTotalQuestions(set) {
      let result = 0

      Object.keys(set).forEach(key => {
        result += set[key]
      })

      return result
    },

    async initPage() {

      if ( !this.component.task ) {
        return
      }
      this.loading = true
      let result = await this.GET_TASK_EDIT( {uuid : this.component.task.value , lang : this.selectedLang } )
      if ( !result ) {
        this.loading = false
        return
      }
      this.loading = false

      this.entity = result.task
      this.test = result.test
      this.questionBanks = result.question_banks
      if ( this.test.question_sets.length > 0 ) {
        this.$emit('testReady')
      }

    },

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
</style>