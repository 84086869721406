<template>
  <v-hover v-slot="{hover}" >
    <v-sheet
        style="position : relative"
        class="wsRoundedLight mb-2"
        :style="`border : 1px solid ${wsBACKGROUND}`">

      <v-sheet
          :color="hover || edit || editDropDownOn ? wsLIGHTCARD : ''"
          style="position : relative"
          width="100%"
          class="d-flex  justify-space-between align-center py-3 transition-ease-in-out pointer">

        <v-sheet width="700" color="transparent" class="d-flex align-center "  >

          <!-- Drag Icon-->
          <v-sheet color="transparent" width="10" class="component-drag mr-2">
            <v-icon
                v-if="hover || edit || editDropDownOn"
                style="cursor: grab"
                :color="wsACCENT">
              mdi-drag-vertical
            </v-icon>
          </v-sheet>
          <!-- Expand Icon-->
          <v-icon
              @click="expandAction()"
              :class="expand ? 'rotate' : ''"
              class="pointer"
              :color="wsACCENT">
            mdi-menu-right
          </v-icon>
          <!-- Component Type Icon-->
          <v-icon
              @click="expandAction"
              :color="getEntityTypeIconColor(component)"
              class="mr-2"
          >
            {{ getEntityTypeIcon(component.entity_type )   }}
          </v-icon>
          <!-- Component name Icon-->
          <h5 v-if="!edit"
                @click="textClicked"
                style="outline: none; font-size: 16px; font-weight: 700 !important ; resize: none "
            >
              <span      v-html="component.name"></span>
              <!-- Component edit name Icon-->
              <v-icon
                  @click="startEdit"
                  v-ripple v-if="hover || edit || editDropDownOn"
                  :color="wsACCENT"
                  class="ml-2">
                mdi-pencil
              </v-icon>
            </h5>
          <textarea
              :key="component.uuid"
              :id="component.uuid"
              v-else
              v-model="component.name"
              @change="editComponent"
              @blur="edit=false"
              @keydown.enter="editComponent"
              :readonly="!edit"
              ref="text"
              v-autowidth="{maxWidth: '620px', minWidth: '20px', comfortZone: 620}"
              v-autogrow
              class="readOnlyCursor text-decoration-underline"
              type="text"
              :placeholder="$t('EnterComponentName')"
              style="outline: none; font-size: 16px; width: 620px; max-width: 620px; font-weight: 700 !important ; resize: none "
              rows="1"
          />

        </v-sheet>

        <!-- Warnings-->
        <!-- Is Not Published-->
        <ws-tooltip :text="$t('ComponentNotPublishedDescription')" v-if="!component.is_published" >
          <v-icon  small class="mr-1" >mdi-eye-off-outline</v-icon>
        </ws-tooltip>
        <!-- Has No Payment Plans-->
        <ws-tooltip v-if="paymentPlanWarningCondition" :text="$t('NoPaymentPlanComponentWarning')" >
          <v-icon :color="wsATTENTION"  small class="mr-1" >mdi-alert-octagon</v-icon>
        </ws-tooltip>

        <!-- Item Edit Button-->
        <v-sheet width="35" color="transparent" class="pr-2">
        <ft-select
            @input="editAction"
            :items="editComponentActionsSelect"
            @expand="editDropDownOn = $event"
            left left-icons >
          <ws-icon-button  class="mr-4" v-if="hover || edit || editDropDownOn" />
        </ft-select>
        </v-sheet>

      </v-sheet>

      <v-expand-transition mode="out-in">
        <div v-if="expand">
          <v-divider />

          <v-sheet v-if="!component.is_published || paymentPlanWarningCondition" :color="wsLIGHTCARD" class="wsRoundedHalf ma-3 mt-5 py-1 px-3" :style="`border : 1px solid ${wsBACKGROUND}`">

            <div v-if="!component.is_published" class="d-flex justify-space-between align-center ">
              <h5 class="text-no-wrap py-1">
                <v-icon class="mr-2" >mdi-eye-off-outline</v-icon>
                <span v-if="!component.is_published">{{ $t('NotPublished') }}</span>
              </h5>
              <v-btn v-if="!newCourse"
                     @click="publishComponent"
                     rounded text class="noCaps" :color="wsACCENT">{{ $t('Publish') }}</v-btn>
              <h6 v-else>{{ $t('PublishComponentDescriptionNewCourse') }}</h6>
            </div>

            <div v-if="paymentPlanWarningCondition" class="d-flex justify-space-between align-center ">
              <h5 class="text-no-wrap py-1">
                <v-icon :color="wsATTENTION" class="mr-2" >mdi-alert-octagon</v-icon>
                <span >{{ $t('NoPaymentPlan') }}</span>
              </h5>

              <ft-select
                  v-if="paymentPlans.length > 0"
                  v-model="selectedPaymentPlans"
                  :items="paymentPlans"
                  :close-on-click="false"
                  @expand="setComponentPaymentPlans"
                  multiple
              >
                <v-btn rounded text class="noCaps" :color="wsACCENT">
                  {{ $t('PaymentPlans') }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </ft-select>
              <v-btn
                  v-else
                  :to="businessDashLink(`courses/${component.course_id}/payment_plans`)"
                  rounded text class="noCaps" :color="wsACCENT">
                {{ $t('PaymentPlans') }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>


          </v-sheet>

          <v-sheet  :id="component.uuid"  class="">

            <courseEditorLecture
                v-if="component.entity_type === 'lecture'  "
                :component="component"
                :key="component.uuid + 'lecture' "
            />
            <course-editor-task
                v-if="component.entity_type === 'task'  "
                :component="component"
                :key="component.uuid + 'task' "
            />
            <course-editor-test
                v-if="component.entity_type === 'task_test'  "
                :component="component"
                @testReady="component.hasTest = true"
                :key="component.uuid + 'test' "
            />
            <course-editor-video-meeting
                v-if="component.entity_type === 'task_video'  "
                :component="component"
                :key="component.uuid + 'task_video' "
            />
            <courseEditorFile
                v-if="component.entity_type === 'file'  "
                v-model="component"
                :key="component.uuid + 'file' "
            />

            <courseEditorVideo
                v-if="component.entity_type === 'video'  "
                v-model="component"
                @input="$emit('updateComponent',$event)"
                :key="component.uuid + 'video' "
            />

          </v-sheet>

        </div>
      </v-expand-transition>

      <!-- Add Item Button-->
      <div v-if="(hover || edit || editDropDownOn) && !expand"
           style="z-index:999; position: absolute; bottom : -30px ; right: 0; left: 0 ; margin-left : auto; margin-right: auto">

        <courseEditorAddItems
            @addItem="addItem($event)"
            @expand="editDropDownOn = $event"
        />

      </div>


    </v-sheet>
  </v-hover>
</template>

<script>

import {mapActions, mapState} from "vuex";

import courseEditorLecture
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/courseEditorLecture";
import courseEditorTask from "@/components/pages/westudy/admin/course/coureseEditor/compenents/courseEditorTask";
import courseEditorFile
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/courseEditorFile";
import courseEditorVideo
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/courseEditorVideo";
import courseEditorTest
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/courseEditorTest";
import courseEditorVideoMeeting
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/courseEditorVideoMeeting";
import courseEditorAddItems
  from "@/components/pages/westudy/admin/course/coureseEditor/UI/courseEditorAddItems";


export default {
  name: "courseEditorComponent",
  components : {
    courseEditorLecture,
    courseEditorFile,
    courseEditorVideo,
    courseEditorTest,
    courseEditorTask,
    courseEditorVideoMeeting,
    courseEditorAddItems
  },
  props : {
    value : {
      type : Boolean,
      default : true,
    },
    component : {
      type : Object,
      default() { return {} }
    },
    index : {
      type : Number,
    },
    topicIndex : {
      type : Number,
    },
    moduleIndex : {
      type : Number,
    },
    newCourse : {
      type : Boolean,
      default : false,
    },
    courseHasPaymentPlans : {
      type : Boolean,
      default : false
    },
    paymentPlans : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      expand : false,
      edit : false,
      editDropDownOn : false,
      selectedPaymentPlans : []
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    editComponentActionsSelect() {
      let items =  [
        // { text : this.$t('Duplicate') , value: 'duplicate' , icon : 'mdi-content-copy' },
        {
          text : this.$t(this.component.is_published ? 'UnPublish' : 'Publish') ,
          value: this.component.is_published ? 'unpublish' : 'publish' ,
          icon : this.component.is_published ? 'mdi-power' : 'mdi-reload' ,
          action : this.component.is_published ?  this.unPublishComponent : this.publishComponent
        },
        { text : this.$t('Delete') , value: 'delete' , icon : 'mdi-delete-outline' },
      ]

      return items
    },
    paymentPlanWarningCondition() {
      return this.courseHasPaymentPlans && this.component.payment_plan_id && this.component.payment_plan_id.length === 0
    }
  },
  methods : {

    ...mapActions('courses', [
        'ADD_MODULE',
        'ADD_TOPIC',
        'EDIT_COMPONENT',
        'DELETE_COMPONENT',
        'ADD_COMPONENT',
        'PUBLISH_COMPONENT',
        'UNPUBLISH_COMPONENT',
        'EDIT_COMPONENT_PAYMENT'
    ]),

    async setComponentPaymentPlans(value) {
      if ( value ) {
        return
      }
      if ( this.selectedPaymentPlans.length === 0 ) {
        return
      }

      let data = {
        uuid : this.component.uuid,
        payment_plan_id : this.selectedPaymentPlans
      }
      let result = await this.EDIT_COMPONENT_PAYMENT(data)
      if ( !result ) {
        return
      }
      this.component.payment_plan_id = this.selectedPaymentPlans

      this.notify(this.$t('ChangesSaved'))
    },
    async publishComponent() {
      if ( this.courseHasPaymentPlans && this.component.payment_plan_id.length === 0) {
        return this.notify(this.$t('ComponentPublishErrorPaymentPlan') , 'warning')
      }
      let result = await this.PUBLISH_COMPONENT(this.component.uuid)
      if ( !result ) {
        return
      }
      this.component.is_published = true
      this.notify(this.$t('ComponentPublished') , 'success')
    },
    async unPublishComponent() {
      let result = await this.UNPUBLISH_COMPONENT(this.component.uuid)
      if ( !result ) {
        return
      }
      this.component.is_published = false
      this.notify(this.$t('ComponentUnPublished') )
    },
    addItem(type) {

      if ( type === 'module') {
        this.addModule()
      } else if ( type === 'topic') {
        this.addTopic()
      } else {
        this.addComponent(type)
      }
    },
    editAction(action) {
      if ( action === 'delete' ) {
        this.deleteComponent()
      }
    },


    async editComponent() {

      this.edit = false

      let entityData = {
        uuid : this.component.uuid,
        lang : this.selectedLang,
        name : this.component.name
      }

      let data = await this.EDIT_COMPONENT(entityData)

      if (!data) {
        return this.notify(this.$t('Error'))
      }

      // this.notify(this.$t("ChangesSaved"))

    },
    async deleteComponent() {

      if ( !await this.DELETE_COMPONENT(this.component.uuid) ) {
        return this.notify(this.$t('EntityCannotBeDeleted'))
      }

      let data = {
        entity : 'component' ,
        componentIndex : this.index,
        topicIndex : this.topicIndex ,
        moduleIndex : this.moduleIndex,
      }

      this.$emit('delete' , data )
      this.notify(this.$t('ComponentDeleted'))

    },

    async addModule() {

      let data = {
        course_id : this.component.course_id,
        lang : this.selectedLang,
        block : this.moduleIndex + 2
      }

      let result = await this.ADD_MODULE( data )
      if ( !result ) {
        return
      }

      this.$emit('update' , { entity : 'module' , data : result } )

    },
    async addTopic() {

      let data = {
        module_id : this.component.module_id,
        lang : this.selectedLang,
        order_number : this.topicIndex + 2
      }

      let result = await this.ADD_TOPIC(data)

      if ( !result ) {
        return
      }
      this.$emit('update' , { entity : 'topic' , data : result } )

    },
    async addComponent(type) {

      let data = {
        entity_type : type,
        lang : this.selectedLang,
        topic_id : this.component.topic_id,
        order_number : this.component.order_number + 1
      }

      let result = await this.ADD_COMPONENT(data)

      this.$emit('update' , { entity : 'component' , data : result } )

    },

    // technical
    getEntityTypeIcon( type ) {

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    getEntityTypeIconColor( component ) {
      let type = component.entity_type

      if ( type === 'task_test' && component.hasTest ) {
        return this.wsATTENTION
      }
      if ( type === 'video' && !component.video && component.file ) {
        return this.wsATTENTION
      }

      if ( type === 'task_video' ) { type = 'task' }
      if ( type === 'file' ) { type = 'file_lang' }
      return component[type] ? this.wsATTENTION : 'grey'
    },

    startEdit() {
      this.edit = true
      this.$nextTick(() => {
        let text = this.$refs.text
        let end = text.value.length || 1
        text.setSelectionRange(end,end)
        text.focus()
      })
    },
    finishEdit($event) {
 //     this.editDropDownOn = false
      this.edit = false
      let textDom = this.$refs.text
      let text = $event.target.innerText


      if ( text.trim().length === 0 ) {
        textDom.replaceChildren()
        this.component.name = ''
      } else {
        this.component.name = text || null
            .replace(/&nbsp;/g, " ")
      }

      this.editComponent()


      this.$emit('input',this.text)
    },
    expandAction() {
      this.expand = !this.expand
    },
    getTextStyle() {
      let style = this.edit ? 'cursor : text;' : ' cursor : pointer;';

      if (!this.component.name) {
        style += `color : ${this.wsDARKLIGHT}`
      }

      return style
    },
    textClicked() {
      if ( !this.edit ) {
        this.expandAction()
      }
    },
  },
  mounted() {
    if ( this.component.expand ) {
      this.expand = true
    }
  }
}
</script>

<style scoped>

.rotate {
  transform: rotate(90deg);
}

.text[placeholder]:empty:before {
  content: attr(placeholder);
}

[contenteditable=true]:empty:not(:focus):before{
  content:attr(data-ph);
}
</style>